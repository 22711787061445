







import { Component, Vue } from 'vue-property-decorator';
import PageContainer from '~/components/core/PageContainer.vue';

@Component({
	components: {
		PageContainer,
	},
})
export default class Index extends Vue {
	// Esta es la prueba fallida de añadir el script de bitrix
	// script: [
	// 	{
	// 		src: "(function(w,d,u){ var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0); var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h); })(window,document,'https://crm.a3satel.com/upload/crm/form/loader_5_coc6eb.js');",
	// 	},
	// ],
	head() {
		return {
			title: 'Tienda',
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: 'none',
				},
				{
					hid: 'referrer',
					name: 'referrer',
					content: 'always',
				},
				{
					hid: 'description',
					name: 'description',
					content: 'Descripción de la tienda de A3 Satel.',
				},
				{
					hid: 'og:title',
					name: 'og:title',
					content: 'Tienda',
				},
				{
					hid: 'og:description',
					name: 'og:description',
					content: 'Descripción de la tienda de A3 Satel.',
				},
				{
					hid: 'twitter:description',
					name: 'twitter:description',
					content: 'Descripción de la tienda de A3 Satel.',
				},
			],
		};
	}
}
